import React, { memo } from 'react'
import { Text } from '@chakra-ui/react'
import { ArrowForwardIosRounded } from '../../../../../atoms/Icon'

const First = memo(() => {
	return (
		// <Tooltip hasArrow label="This will be the first media" fontSize="xs" openDelay={500} placement="top">
		<Text
			px="0.25rem"
			backgroundColor="rgba(0,0,0,0.6)"
			color="white"
			alignItems="center"
			display="flex"
			fontSize="0.625rem"
			borderRadius="sm">
			First
			<ArrowForwardIosRounded color="white" minW="0.625rem" ml="0.25rem" />
		</Text>
		// </Tooltip>
	)
})

export default First

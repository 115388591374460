import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'
import { MusicOffRounded } from '../../../../../atoms/Icon'

const BackgroundMusic = memo(() => {
	return (
		// <Tooltip hasArrow label="Background music muted during this segment" fontSize="xs" openDelay={500}>
		<Box
			// px="0.25rem"
			display="flex"
			alignItems="center"
			justifyContent="center"
			w="20px"
			h="20px"
			backgroundColor="rgba(0,0,0,0.6)"
			borderRadius="sm">
			<MusicOffRounded color="white" width="0.85rem" />
		</Box>
		// </Tooltip>
	)
})

export default BackgroundMusic

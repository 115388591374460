import React, { memo } from 'react'
import { Box, Text } from '@chakra-ui/react'

const Selected = memo(({ index }) => {
	return (
		<Box
			backgroundColor="rgba(1, 102, 204, 0.4)"
			boxShadow="0 0 0 5px var(--vidday-colors-pink-500"
			position="absolute"
			top="0"
			left="0"
			right="0"
			bottom="0">
			<Text
				fontWeight="500"
				color="white"
				backgroundColor="rgba(1, 102, 204, 0.8)"
				w="2rem"
				h="2rem"
				display="flex"
				fontSize="1.25rem"
				alignItems="center"
				justifyContent="center"
				position="absolute"
				borderRadius="md"
				right="2.25rem"
				top="0.5rem"
				maxH="29px"
				// bottom="0"
				// left="0"
			>
				<strong>{index}</strong>
			</Text>
		</Box>
	)
})

export default Selected

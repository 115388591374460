import React, { useEffect, useState, memo } from 'react'
import { chakra, AspectRatio, Flex, Text, Image } from '@chakra-ui/react'
import { computeMimeType } from '../../../../../../contexts/MediaItemProvider'
import { Spinner, Box } from '@chakra-ui/react'
import Processing from '../../../../../atoms/Processing'
import { MdAutoAwesome } from 'react-icons/md'

/** Hook used compute the text card text */
const useTextCardText = (item, gridSize) => {
	const type = computeMimeType(item.mimeType)

	const [text, setText] = useState('')
	const [isBlank, setIsBlank] = useState(false)

	/** Compute blank text */
	useEffect(() => {
		const textBlank = item.text == null || item.text == undefined || item.text == '' || item.text == ' '
		setIsBlank(textBlank)
	}, [item])

	/** Compute text content to display based on grid size */
	useEffect(() => {
		let charLimit //, dragSize;
		switch (gridSize) {
			case 'small':
				charLimit = 30
				// dragSize = 80
				break
			case 'medium':
				charLimit = 60
				// dragSize = 125
				break
			case 'large':
				charLimit = 110
				// dragSize = 150
				break
			default:
				charLimit = 50
			// dragSize = 125
		}

		if (type == 'textcard') {
			const computedText =
				item.text && item.text.length > charLimit ? `${item.text.substring(0, charLimit)}[...]` : item.text
			setText(computedText)
		} else setText('')
	}, [gridSize, item])

	return { text, isBlank }
}

const handleMissingImage = (e) => {
	e.target.src = 'https://static.vidday.com/graphics/missing_thumbnail.svg'
	e.target.classList.add('error')
}

const MemoizedImage = memo(({ path }) => {
	// const imageRef = useRef(null)
	const [loading, setLoading] = useState(true)

	const imageLoaded = () => {
		setLoading(false)
	}

	return (
		<>
			{loading && (
				<Box sx={{ position: 'relative' }}>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}>
						<Spinner thickness="2px" speed="0.45s" emptyColor="gray.200" color="link" size="sm" />
					</Box>
				</Box>
			)}

			<chakra.img
				borderRadius="md"
				src={path}
				onLoad={imageLoaded}
				onError={(e) => {
					handleMissingImage(e)
				}}
				display={loading ? 'none' : 'block'}
				sx={{
					pointerEvents: 'none',
					userSelect: 'none',
					userDrag: 'none',
				}}
				draggable="false"
			/>
		</>
	)
})

const containerStyles = {
	height: '100%',
	p: '0.5rem',
	alignItems: 'center',
	position: 'absolute',
	top: '0',
	bottom: '0',
	textAlign: 'center',
	left: '0',
	right: '0',
	justifyContent: 'center',
	pointerEvents: 'none',
	userSelect: 'none',
	userDrag: 'none',
}

const selectUpscaleStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	transition: 'all .3s ease-in-out',
	div: {
		zIndex: '1',
		pos: 'absolute',
		top: '28%',
		boxShadow: '0px 0px 5px rgba(0,0,0,.5)',
	},
	p: {
		zIndex: '1',
		pos: 'absolute',
		top: '50%',
		textShadow: '0px 0px 3px #000',
		color: 'white',
		fontWeight: 700,
		fontFamily: 'nexa',
		// backgroundColor: 'rgba(255,255,255,.9)',
		// borderRadius: '25px',
		padding: '2px 8px',
	},
}

const Thumbnail = ({ item, gridSize, processingText }) => {
	const type = computeMimeType(item.mimeType)
	const path = item.signedThumbnailUrl
	const hasUpscaledImage = item.jobId?.split(':')[0] == 'nero' && item.upscaledKey
	const itemJobState = item.jobState == 'processing' || item.jobState == 'error'

	let rotationStyles = {}

	if (item.rotation != null) {
		switch (Number(item.rotation)) {
			case 0:
				rotationStyles = { transform: 'rotate(0deg)' }
				break
			case 90:
				rotationStyles = { transform: 'rotate(90deg)' }
				break
			case 180:
				rotationStyles = { transform: 'rotate(180deg)' }
				break
			case 270:
				rotationStyles = { transform: 'rotate(270deg)' }
				break
			default:
				rotationStyles = { transform: 'rotate(0deg)' }
				break
		}
	}

	return (
		<>
			{(!path || itemJobState || item.occupied || hasUpscaledImage) && type != 'textcard' && (
				<Flex sx={containerStyles} fontWeight="semibold">
					{hasUpscaledImage ? (
						<Box sx={selectUpscaleStyles}>
							<Box style={{ padding: '.5rem', background: '#fff', borderRadius: '50%' }}>
								<MdAutoAwesome style={{ transform: 'rotate(90deg)' }} />
							</Box>
							<Text>Review Upscale</Text>
						</Box>
					) : (
						<Processing text={item.jobState == 'error' ? 'Error' : processingText || 'Processing'} />
					)}
				</Flex>
			)}
			{type != 'textcard' && !itemJobState && (
				<AspectRatio borderRadius="md" ratio="1" w="inherit" {...rotationStyles}>
					<MemoizedImage path={path} />
				</AspectRatio>
			)}

			{type == 'textcard' && <TextCardView item={item} gridSize={gridSize} />}
		</>
	)
}

const TextCardView = ({ item, gridSize }) => {
	const { text, isBlank } = useTextCardText(item, gridSize)

	return (
		<Flex sx={containerStyles} backgroundColor="gray.100" borderRadius="md">
			{isBlank && (
				<chakra.img
					borderRadius="md"
					onmousedown={() => {
						return false
					}}
					src="/assets/images/icons/icon_text_placeholder.svg"
					sx={{
						pointerEvents: 'none',
						userSelect: 'none',
						userDrag: 'none',
					}}
					draggable="false"
				/>
			)}
			{!isBlank && <Text variant="mediaGalleryText">{text}</Text>}
		</Flex>
	)
}

export default Thumbnail

import React, { memo } from 'react'
import { Box, Tooltip } from '@chakra-ui/react'
import { WarningRounded } from '../../../../../atoms/Icon'
const Attention = memo(({ ...rest }) => {
	return (
		<Tooltip
			hasArrow
			label="This media is low resolution and may appear blurry."
			fontSize="xs"
			openDelay={500}
			placeContent="auto">
			<Box
				// px="0.25rem"
				display="flex"
				alignItems="center"
				justifyContent="center"
				w="18px"
				h="18px"
				backgroundColor="#ffaf2f"
				opacity="75%"
				borderRadius="sm"
				{...rest}>
				<WarningRounded color="white" width="0.85rem" />
			</Box>
		</Tooltip>
	)
})

export default Attention

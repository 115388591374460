import React, { memo } from 'react'
import { Text } from '@chakra-ui/react'
import { ArrowBackIosNewRounded } from '../../../../../atoms/Icon'

const Last = memo(() => {
	return (
		// <Tooltip hasArrow label="This will be the last media" fontSize="xs" openDelay={500} placement="top">
		<Text
			px="0.25rem"
			backgroundColor="rgba(0,0,0,0.6)"
			color="white"
			alignItems="center"
			display="flex"
			fontSize="0.625rem"
			borderRadius="sm">
			<ArrowBackIosNewRounded color="white" width="0.625rem" mr="0.25rem" />
			Last
		</Text>
		// </Tooltip>
	)
})

export default Last

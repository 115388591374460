/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { Component } from 'react'
import { Box, chakra, keyframes } from '@chakra-ui/react'

/* **************************************
 *  preloader (processing_anim.styl)
 * ************************************ */

export const preloader = keyframes`
    50% {
        transform: rotate(540deg);
    }
`

const processingWheelStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	transition: 'all .3s ease-in-out',
	p: {
		zIndex: '1',
		pos: 'absolute',
		textShadow: '0px 0px 3px #ffffff',
		backgroundColor: 'rgba(255,255,255,.9)',
		borderRadius: '25px',
		padding: '2px 8px',
	},
}

const loaderStyles = {
	maxWidth: '16rem',
	w: '100%',
	height: 'auto',
	strokeLinecap: 'round',
	transform: 'scale(0.8)',

	circle: {
		'fill': 'none',
		'strokeWidth': '8',
		'animationName': `${preloader}`,
		'animationDuration': '5s',
		'animationIterationCount': 'infinite',
		'animationcount': 'infinite',
		'animationTimingFunction': 'ease-in-out',
		'animationFunction': 'ease-in-out',
		'transformOrigin': '170px 170px',
		'willChange': 'transform',
		'&:nth-of-type(1)': {
			stroke: '#48cfae',
			strokeDasharray: '540',
			animationDelay: '-0.1s',
		},
		'&:nth-of-type(2)': {
			stroke: '#4fc0e8',
			strokeDasharray: '450',
			animationDelay: '-0.2s',
		},
		'&:nth-of-type(3)': {
			stroke: '#ac92ed',
			strokeDasharray: '360',
			animationDelay: '-0.3s',
		},
		'&:nth-of-type(4)': {
			stroke: '#f5706c',
			strokeDasharray: '275',
			animationDelay: '-0.4s',
		},
		'&:nth-of-type(5)': {
			stroke: '#fec95c',
			strokeDasharray: '190',
			animationDelay: '-0.5s',
		},
	},
}

/* **************************************
 *  COMPONENT
 * ************************************ */

class Processing extends Component {
	render() {
		const { text, className, ...rest } = this.props
		return (
			<Box id="processing" sx={processingWheelStyles} className={` ${className}`} {...rest}>
				{text && <p>{text}</p>}
				<chakra.svg sx={loaderStyles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
					<circle cx="170" cy="170" r="160" strokeWidth="6.5" />
					<circle cx="170" cy="170" r="135" strokeWidth="6.5" />
					<circle cx="170" cy="170" r="110" strokeWidth="6.5" />
					<circle cx="170" cy="170" r="85" strokeWidth="6.5" />
					<circle cx="170" cy="170" r="60" strokeWidth="6.5" />
				</chakra.svg>
			</Box>
		)
	}
}

export default Processing

import React, { memo } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { sec2Time } from '@vidday/utils'
import { computeMimeType } from '../../../../../../contexts/MediaItemProvider'
import { ImageRounded, VideocamRounded, TextSnippetRounded } from '../../../../../atoms/Icon'
import { isBrowser } from 'react-device-detect'

const SectionVideo = memo(({ item, duration, iconSize }) => {
	return (
		<>
			{/* <Tooltip hasArrow label="Video" fontSize="xs" openDelay={500}> */}
			<Flex align="center">
				<VideocamRounded color="white" fontSize={iconSize} />
				{item.default && isBrowser && (
					<Text ml="0.25rem" color="white" fontWeight="500" fontSize="0.775rem">
						SAMPLE
					</Text>
				)}
			</Flex>
			{/* </Tooltip> */}
			{/* <Tooltip hasArrow label="Slide duration" fontSize="xs" openDelay={500}> */}
			<Text color="white" fontSize="0.875rem">
				{duration}
			</Text>
			{/* </Tooltip> */}
		</>
	)
})

const SectionImage = memo(({ item, duration, iconSize }) => {
	return (
		<>
			{/* <Tooltip hasArrow label="Image" fontSize="xs" openDelay={500}> */}
			<Flex align="center">
				<ImageRounded color="white" fontSize={iconSize} />
				{item.default && isBrowser && (
					<Text ml="0.25rem" color="white" fontWeight="500" fontSize="0.775rem">
						SAMPLE
					</Text>
				)}
			</Flex>
			{/* </Tooltip> */}
			{/* <Tooltip hasArrow label="Slide duration" fontSize="xs" openDelay={500}> */}
			<Text color="white" fontSize="0.875rem">
				{duration}
			</Text>
			{/* </Tooltip> */}
		</>
	)
})

const SectionText = memo(({ item, duration, iconSize }) => {
	return (
		<>
			{/* <Tooltip hasArrow label="Text Card" fontSize="xs" openDelay={500}> */}
			<Flex align="center">
				<TextSnippetRounded color="white" fontSize={iconSize} />
				{item.default && isBrowser && (
					<Text ml="0.15rem" color="white" fontWeight="500" fontSize="0.775rem">
						SAMPLE
					</Text>
				)}
			</Flex>
			{/* </Tooltip> */}
			{/* <Tooltip hasArrow label="Slide duration" fontSize="xs" openDelay={500}> */}
			<Text color="white" fontSize="0.875rem">
				{duration}
			</Text>
			{/* </Tooltip> */}
		</>
	)
})

const BottomSection = memo(({ item, hasCollageBackground, gridSize }) => {
	const type = computeMimeType(item.mimeType)

	/** Deconstruct item */
	const { foregroundEligible, duration, startTime, endTime } = item

	const iconSize = gridSize == 'small' ? '1.25rem' : '1.5rem'

	/** Compute media duration */
	const getDuration = duration ? Math.floor(duration) : 0
	const computedStartTime = type == 'video' && startTime ? startTime : 0 // in seconds
	const computedEndTime = type == 'video' && endTime && endTime <= duration ? endTime : getDuration // in seconds
	const computedDuration = sec2Time(Math.floor(computedEndTime - computedStartTime))

	return (
		<Box
			position="absolute"
			bottom="0"
			left="0"
			right="0"
			background="linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%)"
			h="75px"
			borderBottomRadius="md">
			<Flex
				p={gridSize == 'small' ? '0.3rem' : '0.5rem'}
				justify="space-between"
				align="center"
				w="full"
				position="absolute"
				bottom="0"
				left="0"
				right="0">
				{/* <Tooltip hasArrow label="Image" fontSize="xs" openDelay={500}>
				<Icon as={ImageRoundedIcon} color="white" />
			</Tooltip>
			<Tooltip hasArrow label="Slide duration" fontSize="xs" openDelay={500}>
				<Text color="white" fontSize="0.875rem">
					0:30
				</Text>
			</Tooltip> */}

				{type == 'video' && (foregroundEligible || !hasCollageBackground) && item.duration > 0 && (
					<SectionVideo
						item={item}
						iconSize={iconSize}
						duration={computedDuration}
						// isDragging={isDragging}
					/>
				)}

				{type == 'image' && (foregroundEligible || !hasCollageBackground) && (
					<SectionImage
						item={item}
						iconSize={iconSize}
						duration={item.duration == 0 ? '0:04' : computedDuration}
						// isDragging={isDragging}
					/>
				)}

				{type == 'textcard' && (foregroundEligible || !hasCollageBackground) && (
					<SectionText
						item={item}
						iconSize={iconSize}
						duration={item.duration == 0 ? '0:04' : computedDuration}
						// isDragging={isDragging}
					/>
				)}
			</Flex>
		</Box>
	)
})

export default BottomSection
